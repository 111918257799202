import React, { PropsWithChildren } from 'react';
import { OverlayProps } from '~/shared/components';
import { Overlay } from '~/shared/components/Overlay';
import { usePageRequest } from '~/shared/utils/request';
import { DynamicBlockList } from '~/templates/blocks';

export type PageOverlayProps = OverlayProps &
    PropsWithChildren<{
        url: string;
    }>;

/**
 * Overlay that can be used to display the content of a page from a URL.
 * @returns
 */
export const PageOverlay = ({ url, open, ...rest }: PageOverlayProps) => {
    const { data: pageData, isLoading } = usePageRequest(url, open);

    if (url.startsWith('http')) {
        console.error(`Absolute url's are not supported!`);
        return <></>;
    }

    return (
        <Overlay open={open && !isLoading} {...rest}>
            <DynamicBlockList
                elements={(pageData && 'pageElements' in pageData && pageData.pageElements) || []}
            />
        </Overlay>
    );
};
