import { CSSObject } from '@emotion/react';

/**
 * Custom scrollbar light grey with rounded corners
 */
export const scrollbar: CSSObject = {
    // Firefox
    scrollbarColor: 'rgba(0, 0, 0, 0.85), transparent',

    // Chrome, Edge, and Safari
    '&::-webkit-scrollbar': {
        display: 'block',
        width: 4,
    },

    '&::-webkit-scrollbar-track': {
        background: '#F0F0F0',
        cursor: 'default',
    },

    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#000000',
        borderRadius: 8,
        border: '6px solid transparent',
        '&:active, &:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
            cursor: 'pointer',
        },
    },
};

/**
 * Custom scrollbar thin with black thumb
 */
export const scrollbarThin: CSSObject = {
    // Firefox
    scrollbarColor: 'rgba(0, 0, 0, 0.85), transparent',

    // Chrome, Edge, and Safari
    '&::-webkit-scrollbar': {
        display: 'block',
        width: 1,
        height: 1,
    },

    '&::-webkit-scrollbar-track': {
        background: '#F0F0F0',
        cursor: 'default',
    },

    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#000000',
        '&:active, &:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
        },
    },
    WebkitOverflowScrolling: 'touch',
};
