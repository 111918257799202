import styled from '@emotion/styled';
import { Text } from '$shared/components/Text';
import { getClampFullRange } from '~/theme';

/**
 * Wrapper component that will add spacing, table and list styling
 * to default HTML elements
 *
 * @example
 * <TextBlock>
 *      <h1>My header</h1>
 *      <p>My paragraph</>
 * </TextBlock>
 */
export const TextBlock = styled(Text.withComponent('div'))(({ theme }) => ({
    [`p, ol, ul, pre, table`]: {
        '&:not(:last-child)': {
            marginBottom: theme.spaces[3],
        },
    },

    p: {
        wordBreak: 'break-word',
        minHeight: '1em',
        ['&:empty']: {
            display: 'none',
        },
    },

    li: {
        marginBottom: theme.spaces[2],
    },

    ol: {
        paddingLeft: 25,
        listStyle: 'decimal',
    },

    ul: {
        paddingLeft: 25,
        listStyle: 'disc',
    },

    code: {
        fontFamily: theme.fonts.mono,
    },

    pre: {
        backgroundColor: theme.colors.light30,
        padding: 15,
        fontFamily: theme.fonts.mono,
    },
    ['h1, h2, h3, h4, h5, h6']: {
        marginTop: getClampFullRange(24, 32),

        '&:not(:last-child)': {
            marginBottom: '.5em',
        },
    },

    // Nasty fix for avoiding using :first-child
    // Which casues SSR warnings
    [`& > :first-of-type:not(style):not(:first-of-type ~ *),
        & > style + *`]: {
        marginTop: 0,
    },
}));
