import React, { memo, ReactNode } from 'react';
import { useCookieInformation, TextShade, StrokeText } from '~/shared/components';
import { StyledConsentLink } from './styled';

export const ConsentLink = memo(
    ({ children, shade = 'paragraph' }: { children: ReactNode; shade?: TextShade }) => {
        const { renewConsent } = useCookieInformation();
        return (
            <StyledConsentLink onClick={renewConsent} as="button">
                {children}
                <StrokeText shade={shade} />
            </StyledConsentLink>
        );
    }
);
