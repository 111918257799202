import { memo } from 'react';
import { Theme } from '@emotion/react';
import { breakpoints } from '~/theme';
import styled from '@emotion/styled';

type BreakpointKey = keyof typeof breakpoints;
type BreakpointStyles = Record<BreakpointKey, Record<BreakpointKey | string, string>>;
type BreakpointSpace = Partial<Record<BreakpointKey, keyof Theme['spaces'] | '0'>>;

interface SpacerProps {
    space?: keyof Theme['spaces'];
}

/**
 * Spacer component.
 * Can be used to add spacing between elements.
 * @param space - Space between elements.
 * @example
 * <Spacer space="5" />
 *
 * Can also be used with breakpoints:
 * <Spacer sm="3" md="5" />
 *
 * Note: space and breakpoint props are mutually exclusive.
 */
const SpacerComponent = styled.div<SpacerProps & BreakpointSpace>(({ space, theme, ...rest }) => {
    const styleObj = Object.keys(rest).reduce((prev, curr) => {
        const key = breakpoints[curr as BreakpointKey] as BreakpointKey;
        const spaceKey = (rest as Record<string, unknown>)[curr] as keyof Theme['spaces'] | '0';

        if (key && spaceKey) {
            prev[key] = {
                marginTop: spaceKey === '0' ? '0px' : theme.spaces[spaceKey],
            };
        }
        return prev;
    }, {} as BreakpointStyles);

    const hasBreakpointStyles = Object.keys(styleObj).length > 0;

    if (space && hasBreakpointStyles) {
        throw new Error('Both breakpoint and space prop are set. Only one can be set.');
    }

    return hasBreakpointStyles
        ? styleObj
        : {
              marginTop: theme.spaces[space || '5'],
          };
});

export const Spacer = memo(SpacerComponent);
