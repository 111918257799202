/**
 * Wrap string in span, that has the styling from the passed CSSObject
 */

import { isSSR } from '../../platform';

type AllowedStyles = Partial<Omit<CSSStyleDeclaration, 'length' | 'parentRule'>>;

export type GetStyledElementProps = {
    /**
     * The text that should be wrapped in span
     */
    text?: string;

    /**
     * The style that should apply to the span
     */
    style: AllowedStyles;

    /**
     * The HTML element type. Defaults to span.
     */
    tag?: string;
};

export const getStyledElement = ({ text, style, tag = 'span' }: GetStyledElementProps): string => {
    if (!text) {
        return '';
    }
    if (isSSR) {
        return `<${tag}>${text}</${tag}>`;
    }
    const span: HTMLSpanElement = document.createElement(tag);
    Object.assign(span.style, style);
    span.innerText = text;
    return span.outerHTML;
};
