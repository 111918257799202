import React, { PropsWithChildren, useCallback, useState } from 'react';
import { OverlayProps, PageOverlay } from '~/shared/components/Overlay';
import { usePortal } from '~/shared/hooks/usePortal/usePortal';
import { isSSR } from '~/shared/utils';

type Props = PropsWithChildren<{ url: string }> & Omit<OverlayProps, 'open' | 'onDismiss'>;

export const LinkPageOverlay = ({ url, children, ...rest }: Props) => {
    const [open, setOpen] = useState(false);

    // Use a portal to avoid html getting parsed by rich text parser
    const Portal = usePortal(isSSR ? null : document.querySelector('body'));
    const onDismiss = useCallback(() => setOpen(false), [setOpen]);

    return (
        <>
            {/* This span captures bubbled events, which is why linting is mad */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <span
                onClick={(event) => {
                    event.preventDefault();
                    setOpen(true);
                }}
            >
                {children}
            </span>
            <Portal>
                <PageOverlay url={url} {...rest} open={open} onDismiss={onDismiss} />
            </Portal>
        </>
    );
};
