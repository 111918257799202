import { ReactPortal, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

type PortalState = {
    render: ({ children }: { children: JSX.Element }) => ReactPortal | null;
    remove: () => boolean | null;
};
/**
 * Hook that returns a portal attached to a dom node
 *
 * @example
 * const Portal = usePortal(document.querySelector('body'));
 *
 * <Portal>
 *   // Portalized content
 * </Portal>
 */
export const usePortal = (root: HTMLElement | null) => {
    const [portal, setPortal] = useState<PortalState>({
        render: () => null,
        remove: () => null,
    });

    const createPortal = useCallback((el: HTMLElement) => {
        const Portal = ({ children }: { children: JSX.Element }) =>
            ReactDOM.createPortal(children, el);
        const remove = () => ReactDOM.unmountComponentAtNode(el);
        return { render: Portal, remove };
    }, []);

    useEffect(() => {
        if (root) {
            portal.remove();
        }
        if (root) {
            const newPortal = createPortal(root);
            setPortal(newPortal as PortalState);
        }
        return () => {
            portal.remove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [root]);

    return portal.render;
};
