import { PotentialThemeColorType } from '~/theme';
import { useRawHtml } from './hooks';
import { TextBlock } from '~/shared/components/TextBlock';
import { StrokeTextMode } from '../StrokeText';

export type RawHtmlProps = {
    html?: string;
    backgroundColor?: PotentialThemeColorType;
    linkStrokeMode?: StrokeTextMode;
    interpolation?: Record<string, string | number>;
};

export const RawHtml = ({
    html,
    backgroundColor,
    linkStrokeMode,
    interpolation,
    ...rest
}: RawHtmlProps) => {
    const markup = useRawHtml({ html, backgroundColor, linkStrokeMode, interpolation });
    return <TextBlock children={markup} {...rest} />;
};
