import styled from '@emotion/styled';
import { Text } from '~/shared/components/Text';
import { StyledLink } from '~/shared/components/Link/styled';

export const StyledConsentLink = styled(StyledLink.withComponent(Text))({
    margin: 0,
    padding: 0,
    border: 'none',
    background: 'transparent',
});
