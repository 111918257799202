import { StyledText } from '$shared/components/Text';
import styled from '@emotion/styled';

export const StyledTable = styled(StyledText.withComponent('table'))(({ theme }) => ({
    minWidth: '100%',
    borderCollapse: 'collapse',

    // Tables
    'td, th': {
        padding: '0.75em',
        textAlign: 'left',
        border: `1px solid ${theme.colors.light30}`,
        boxSizing: 'border-box',
    },

    th: {
        fontWeight: theme.fontWeights.bold,
        backgroundColor: theme.colors.light30,
        fontSize: theme.fontSizes.xs,
    },

    thead: {
        position: 'sticky',
        top: 0,
        zIndex: 10,
        backgroundColor: '#fff',
    },

    'tbody > tr > th:first-of-type': {
        position: 'sticky',
        left: 0,
        zIndex: 10,
    },
}));
